<template>
  <div>
    <vs-popup :title="$t('appeal.handling.history_')" :active.sync="visible" id="popup-handling-history" @close="closePopup" ref="popup-handling-history" :fullscreen="true">
      <vs-table :data="listHistory" :sst="true" current-sort-type="asc">
          <template slot="thead">
            <vs-th width="20%">{{ $t('data-0') }}</vs-th>
            <vs-th width="15%">{{ $t('appeal.status') }}</vs-th>
            <vs-th>{{ $t('appeal.handling.label') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="data[i]" v-for="(tr, i) in data">
              <vs-td class="break-all p-1 pl-4">
                <div class="w-full text-sm">
                  <span> {{ $utils.format.dateWithFormat(_.get(tr, 'created_at'), 'DD/MM/YYYY - kk:mm:ss') }} </span>
                </div>
              </vs-td>
              <vs-td class="">
                <span>{{ _.get(tr, 'status_str') }}</span>
              </vs-td>
              <vs-td class="">
                <div class="w-full">
                  <div v-html-safe="_.get(tr, 'description')"/>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import AppealService from '@/services/api/AppealService'
import AppealHandlingService from '@/services/api/AppealHandlingService'

export default {
  directives: {

  },
  components: {

  },
  props: {
    appeal_id: {
      default: null,
      type: Number,
    },
    appeal_handling_id: {
      default: null,
      type: Number,
    },
    visible :{
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    serviceHandling: null,
    service: null,
    listHistory: []
  }),
  computed: {

  },
  beforeMount() {
    this.service = AppealService.build(this.$vs)
    this.serviceHandling = AppealHandlingService.build(this.$vs)
  },
  mounted() {

  },
  watch: {
    visible(val){
      if(val){
        if(this.appeal_handling_id){
          this.loadDataAdmin()
        }else{
          this.loadData()
        }
      }
    }
  },
  methods: {
    loadData(){
      this.$vs.loading()
      this.service.listHandlingHistory(this.appeal_id).then(
        data => {
          this.listHistory = data
        },
        error => {this.$vs.loading.close()}
        ).finally(() => {this.$vs.loading.close()})
    },
    loadDataAdmin(){
      this.$vs.loading()
      this.serviceHandling.listHandlingHistory(this.appeal_handling_id).then(
        data => {
          this.listHistory = data
        },
        error => {this.$vs.loading.close()}
        ).finally(() => {this.$vs.loading.close()})
    },
    closePopup(show){
      this.visible = show
      this.$emit('close', show)
    }
  }
}
</script>

<style>

</style>
